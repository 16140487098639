// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-achievements-details-jsx": () => import("./../../../src/pages/achievementsDetails.jsx" /* webpackChunkName: "component---src-pages-achievements-details-jsx" */),
  "component---src-pages-achievements-jsx": () => import("./../../../src/pages/achievements.jsx" /* webpackChunkName: "component---src-pages-achievements-jsx" */),
  "component---src-pages-agreement-country-details-jsx": () => import("./../../../src/pages/agreementCountryDetails.jsx" /* webpackChunkName: "component---src-pages-agreement-country-details-jsx" */),
  "component---src-pages-air-quality-details-jsx": () => import("./../../../src/pages/airQualityDetails.jsx" /* webpackChunkName: "component---src-pages-air-quality-details-jsx" */),
  "component---src-pages-air-quality-jsx": () => import("./../../../src/pages/airQuality.jsx" /* webpackChunkName: "component---src-pages-air-quality-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-copyrights-jsx": () => import("./../../../src/pages/copyrights.jsx" /* webpackChunkName: "component---src-pages-copyrights-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-maps-jsx": () => import("./../../../src/pages/maps.jsx" /* webpackChunkName: "component---src-pages-maps-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacyPolicy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-single-type-jsx": () => import("./../../../src/pages/singleType.jsx" /* webpackChunkName: "component---src-pages-single-type-jsx" */),
  "component---src-pages-usage-policy-jsx": () => import("./../../../src/pages/usagePolicy.jsx" /* webpackChunkName: "component---src-pages-usage-policy-jsx" */),
  "component---src-pages-wild-life-agreement-jsx": () => import("./../../../src/pages/wildLifeAgreement.jsx" /* webpackChunkName: "component---src-pages-wild-life-agreement-jsx" */)
}

