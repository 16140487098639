import * as actionTypes from "../actions/actionTypes"
import { updateObject } from "../../shared/utility"

const initialState = {
	activeNavbarItem: 'home',
}

const navigationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACTIVE_NAVBAR_ITEM:
			return updateObject(state, {
				activeNavbarItem: action.activeNavbarItem
			})
		default:
			return state
	}
}

export default navigationReducer
