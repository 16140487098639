export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}


export const applyArabicCss = () => {
  const enLink = document.getElementById("enCss")
  if (enLink)
    enLink.remove();

  const head = document.body.parentElement.firstElementChild;
  const link = document.createElement('link');
  link.setAttribute('type', "text/css");
  link.setAttribute('rel', "stylesheet");
  link.setAttribute('href', '../../style-edits.css');
  link.setAttribute('id', "arCss");
  head.appendChild(link);
}

export const applyEnglishCss = () => {
  const arLink = document.getElementById("arCss")
  if (arLink)
    arLink.remove();

  const head = document.body.parentElement.firstElementChild;
  const link = document.createElement('link');
  link.setAttribute('type', "text/css");
  link.setAttribute('rel', "stylesheet");
  link.setAttribute('href', '../../style-edits-en.css');
  link.setAttribute('id', "enCss");
  head.appendChild(link);
}
