import React from "react"
import { Provider } from "react-redux"
import store from "./store"
import * as actions from "./actions";
import { applyArabicCss, applyEnglishCss } from "../shared/utility"


export default ({ element }) => {

  if (typeof window !== `undefined`) {
    // workaround forces setting the default language to 'ar'
    // because gatsby ignores the default language @ gatsby-config.js
    // and uses the browser default language
    const userLang = window.localStorage.getItem('user-pref-lang') || 'ar'
    window.localStorage.setItem('gatsby-intl-language', userLang)
    window.localStorage.setItem('user-pref-lang', userLang)
    
    var caurrentLocale = window.location.href.split('/')
      .filter(urlPart => urlPart === 'ar' || urlPart === 'en')[0];
    
    if (caurrentLocale === 'ar') {
      applyArabicCss()
    } else if (caurrentLocale === 'en') {
      applyEnglishCss()
    }

    var currentCountryCode = new URL(window.location.href).searchParams.get("country") || "GCC";
    store.dispatch(actions.fetchRegions(caurrentLocale, currentCountryCode));
    store.dispatch(actions.setActiveNavbarItem(window.location.pathname.split('/')[2] || "home"))
  }

  return <Provider store={store}>
    {element}
  </Provider>
}
