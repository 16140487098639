import * as actionTypes from "../actions/actionTypes"
import { updateObject } from "../../shared/utility"

const initialState = {
  regions: [],
  selectedRegion: null,
}

const fetchRegions = (state, action) => {
  return updateObject(state, {
    regions: action.regions
  })
}

const setSelectedRegion = (state, action) => {
  
  var url = new URL(window.location.href);
  var search_params = new URLSearchParams(url.search);
  search_params.set('country', action.regionCode);
  url.search = search_params.toString();
  window.history.replaceState(null, null, url.toString());

  let selectedRegion = state.regions.find(region => region.code === action.regionCode)
    || state.regions.find(region => region.code === "GCC");

  return updateObject(state, {
    selectedRegion: selectedRegion,
  })
}

const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REGIONS:
      return fetchRegions(state, action)

    case actionTypes.SELECT_REGION:
      return setSelectedRegion(state, action)

    default:
      return state
  }
}

export default regionsReducer
