import axios from "axios"

const baseURL = process.env.SERVER_URL


const instance = (locale, url) => {
  return axios.get(baseURL + locale + "/rest/" + url)
}

export default instance
