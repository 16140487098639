import { combineReducers } from "redux"
import { mapExplorerConfig } from "./mapExplorer"
import regions from "./regions"
import ui from "./ui"

export default combineReducers({
  regions,
  ui,
  mapExplorerConfig: mapExplorerConfig
})
