import * as actionTypes from "./actionTypes"
import axios from "../../api/axios"

export const fetchRegions = (locale, initialSeletedRegionCode) => {
  return dispatch => {
    axios(locale, "regions/").then(response => {
      const regions = [...response.data]
      dispatch(saveRegions(regions))
      dispatch(setSelectedRegion(initialSeletedRegionCode))
    })
  }
}

const saveRegions = regions => {
  return {
    type: actionTypes.FETCH_REGIONS,
    regions: regions,
  }
}

export const setSelectedRegion = regionCode => {
  return {
    type: actionTypes.SELECT_REGION,
    regionCode: regionCode,
  }
}
